.phone-scene-container, .phone-wireframescene-container  {
  position: relative;
  width: 600px;
  height: 1000px;
}

.phone-scene-container > canvas, .phone-wireframescene-container > canvas {
  position: absolute;
  top: 0;
}

@media screen and (max-width: 1020px) {
  .phone-scene-container, .phone-wireframescene-container {
    width: 300px;
    height: 500px;
  }
}

@media screen and (max-width: 500px) {
  .phone-scene-container, .phone-wireframescene-container {
    width: 250px;
    height: 400px;
  }
}