$canvas-size-l:           350px;
$canvas-size-m:           250px;
$canvas-size-s:           200px;
$cell-size:               5%;
$cell-color:              var(--util-900);
$cell-border-color:       var(--util-950);
$cell-food-color:         var(--primary-500);
$cell-food-super-color:   var(--secondary-500);
$cell-sewage-color:       #aeff4b;
$cell-snake-head-color:   var(--util-50);
$cell-snake-tail-color:   var(--util-50);
$transition-container:    all .2s ease-out;

.snake-game {
  position: relative;
  display: inline-block;
  z-index: 100;
  max-width: 100%;
  margin: 0;
  left: 0;
  transform: none;
  transition: $transition-container;
}

.snake-game.playing {
  transform: translateX(-50%);
  left: 50%;
  margin-top: 32px;
  margin-bottom: 32px;
}

.snake-game-container {
  position: relative;
  display: inline-block;
  z-index: 1;
  max-width: 100%;
}

.snake-game-instruction {
  transition: opacity .3s ease-out;
}

.snake-game-score {
  position: absolute;
  top: 50%;
  right: -50px;
  transform: translateY(-50%) rotate(90deg);
}

.snake-game-canvas {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: $canvas-size-l;
  height: $canvas-size-l;
}

@media screen and (max-width: 1020px) {
  .snake-game-canvas {
    width: $canvas-size-m;
    height: $canvas-size-m;
  }

  .snake-game-score {
    right: calc(-20px - 2.3vw);
  }
}

@media screen and (max-width: 500px) {
  .snake-game-canvas {
    width: $canvas-size-s;
    height: $canvas-size-s;
  }

  .snake-game-score {
    right: -30px;
  }
}

.snake-game-canvas > .cell {
  z-index: 0;
  width: $cell-size;
  height: $cell-size;
  background-color: $cell-color;
  border: 1px solid $cell-border-color;
}

.snake-game-canvas > .cell.food {
  z-index: 2;
  background-color: $cell-food-color;
  -webkit-box-shadow: 0px 0px 15px -3px $cell-food-color;
  box-shadow: 0px 0px 15px -3px $cell-food-color;
}

.snake-game-canvas > .cell.food-super {
  z-index: 2;
  background-color: $cell-food-super-color;
  -webkit-box-shadow: 0px 0px 25px -3px $cell-food-super-color;
  box-shadow: 0px 0px 25px -3px $cell-food-super-color;
}

.snake-game-canvas > .cell.sewage {
  z-index: 2;
  background-color: $cell-sewage-color;
  -webkit-box-shadow: 0px 0px 25px -3px $cell-sewage-color;
  box-shadow: 0px 0px 25px -3px $cell-sewage-color;
}

.snake-game-canvas > .cell.snake-head {
  z-index: 1;
  background-color: $cell-snake-head-color;
  -webkit-box-shadow: 0px 0px 15px -3px $cell-snake-head-color;
  box-shadow: 0px 0px 15px -3px $cell-snake-head-color;
}

.snake-game-canvas > .cell.snake-tail {
  z-index: 1;
  background-color: $cell-snake-tail-color;
  -webkit-box-shadow: 0px 0px 15px -3px $cell-snake-tail-color;
  box-shadow: 0px 0px 15px -3px $cell-snake-tail-color;
}

.snake-game-container .turn-up {
  position:absolute;
  width: 100%;
  height: 25vh;
  bottom: 100%;
}

.snake-game-container .turn-down {
  position:absolute;
  width: 100%;
  height: 25vh;
  top: 100%;
}

.snake-game-container .turn-right {
  position:absolute;
  width: 50vw;
  height: 100%;
  left: 100%;
}

.snake-game-container .turn-left {
  position:absolute;
  width: 50vw;
  height: 100%;
  right: 100%;
}

.snake-game > .scoreboard {
  position: relative;
  z-index: 2;
}