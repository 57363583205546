$transition-bg: all .44s cubic-bezier(.03,.49,.33,1);
$transition-screen: all .9s ease-in;

.browser-router {
  transform: scale(.93);
  transition: $transition-bg;
}

.loading-container {
  position: fixed;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.loading-container::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
  transition: $transition-bg;
}

.loading-container > .loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: all;
  background-color: var(--background);
  transform: scale(1);
  transition: $transition-bg;
}

.loading-container.finished::before {
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
}

.loading-container.finished > .loading-screen {
  opacity: 0;
  pointer-events: none;
  transform: scale(4);
}