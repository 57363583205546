$padding-l: 17vw;
$padding-m: 50px;
$padding-s: 25px;

.home-root {
  position: relative;
}

.home-root > .introduction {
  position: relative;
  height: 100vh;
  background-color: var(--util-950);
  overflow: hidden;
}

.home-root > .introduction > .background {
  position: absolute;
  height: 100%;
  width: 50%;
  right: 30%;
  top: 0;
  transform: translateX(50%);
  mix-blend-mode: lighten;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../../public/assets/img/software_developer_face2.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
}

.home-root > .introduction > .background > img {
  min-height: 100%;
}

.home-root .content-box {
  padding-top: 25vh;
  padding-bottom: 25vh;
  position: relative;
}

.introduction > .content-box {
  position: absolute;
  bottom: 0;
  padding-top: 0;
  padding-left: $padding-l;
  padding-right: $padding-l;
}

.introduction > .content-box > .line {
  width: 100%;
  left: calc($padding-l - 50px - 1px);
  border-left: 1px solid var(--foreground);
}

.contact > .line {
  top: 0;
  height: calc(35vh + 1px);
  width: 100%;
  left: calc($padding-l - 50px - 1px);
  border-left: 1px solid var(--foreground);
}

.contact > .content-box > .line {
  width: 100%;
  left: calc($padding-l - 50px - 1px);
  border-left: 1px solid var(--foreground);
}

.content-box > div > p, .content-box > p {
  max-width: 85%;
}

.about .background {
  position: absolute;
  z-index: 1;
  width: 10%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--util-1000);
  overflow: hidden;
}

.about .background > .background-container {
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  padding-top: 25vh;
}

.about > .content-box > .about-text {
  position: relative;
  z-index: 2;
  bottom: 150px;
}

@media screen and (max-width: 580px) {
  .about > .content-box > .about-text {
    bottom: 0;
  }
}

.what-do > .line, .about > .line {
  position: absolute;
  z-index: 2;
  top:0;
  left:0;
  height: 100%;
  overflow:hidden;
  width: calc($padding-l - 50px);
  border-right: 1px solid var(--foreground);
}

.line, .line-top {
  position:absolute;
  top:0;
  left:0;
  height: 100%;
  overflow:hidden;
  width: calc($padding-l - 50px);
}

.line > .line-object {
  position: absolute;
  right: 0;
  z-index: 2;
  height: 100%;
  width: 1px;
  background: linear-gradient(0deg, var(--foreground) 95%, rgba(0,0,0,0) 100%);
}

.line-top {
  height:100px;
}

.line-top > .line-object {
  position: absolute;
  right: 0;
  z-index: 2;
  height: 100%;
  width: 1px;
  background: linear-gradient(180deg, var(--foreground) 30%, rgba(0,0,0,0) 100%);
}

.story > .line > .line-flare {
  box-shadow: 0px 20px 40px var(--secondary-500), 0px -20px 40px var(--primary-500), 20px 0px 40px var(--accent-500), -20px 0px 40px var(--accent-500);
  -moz-box-shadow: 0px 20px 40px var(--secondary-500), 0px -20px 40px var(--primary-500), 20px 0px 40px var(--accent-500), -20px 0px 40px var(--accent-500);
  border-radius: 10em;
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  right: -101px;
}

.home-root .story, .about-text, .padding-box, .my-works .item, .my-works .item > .animated-background > .item, .testimonials, .contact > .content-box {
  padding-left: $padding-l;
  padding-right: $padding-l;
}

@media screen and (max-width: 1020px) {
  .introduction > .content-box, .home-root .story, .about-text, .padding-box, .my-works .item, .my-works .item > .animated-background > .item, .testimonials, .contact > .content-box  {
    padding-left: $padding-m;
    padding-right: $padding-m;
  }

  .line, .line-mask, .line-top, .what-do > .line {
    width: calc($padding-m - 15px);
  }

  .story > .line > .line-flare {
    box-shadow: 0px 10px 20px var(--secondary-500), 0px -10px 20px var(--primary-500), 10px 0px 20px var(--accent-500), -10px 0px 20px var(--accent-500);
    -moz-box-shadow: 0px 10px 20px var(--secondary-500), 0px -10px 20px var(--primary-500), 10px 0px 20px var(--accent-500), -10px 0px 20px var(--accent-500);
  }

  .home-root > .introduction > .background {
    width: 100%;
    right: 50%;
  }

  .introduction > .content-box > .line, .contact > .line, .contact > .content-box > .line {
    left: calc($padding-m - 15px - 1px);
  }
}

@media screen and (max-width: 500px) {
  .introduction > .content-box, .home-root .story, .about-text, .padding-box, .my-works .item, .my-works .item > .animated-background > .item, .testimonials, .contact > .content-box  {
    padding-left: $padding-s;
    padding-right: $padding-s;
  }

  .line, .line-mask, .line-top, .what-do > .line, .about > .line {
    width: calc($padding-s - 7px);
  }

  .introduction > .content-box > .line, .contact > .line, .contact > .content-box > .line {
    left: calc($padding-s - 7px - 1px);
  }
}

.home-root > .testimonials {
  position: relative;
  padding-top: 25vh;
  padding-bottom: 25vh;
}

.home-root > .motto {
  position: relative;
  overflow: hidden;
  height: 100vh;
  background: var(--util-1000);
}

.home-root > .motto > .background{
  position:absolute;
  top: 0;
  left: -20%;
  height: 100%;
  width: 100%;
  mix-blend-mode: lighten;
  background-image: url('../../../public/assets/img/software_developer_cap.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.home-root > .contact {
  position: relative;
  padding-top: 35vh;
  padding-bottom: 35vh;
  overflow: hidden;
}

// Card-item
$card-size-l: 300px;
$card-size-m: 200px;
$card-size-s: 150px;

.card-item {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: var(--util-800);
  transition: transform .2s ease-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.card-light {
  position:absolute;
  z-index: 0;
  width: 97%;
  height: 97%;
  border-radius: 50%;
  box-shadow: 0px 20px 40px var(--secondary-500), 0px -20px 40px var(--primary-500), 20px 0px 40px var(--accent-500), -20px 0px 40px var(--accent-500);
  -moz-box-shadow: 0px 10px 20px var(--secondary-500), 0px -10px 20px var(--primary-500), 10px 0px 20px var(--accent-500), -10px 0px 20px var(--accent-500);
  animation: card-item-before-rotate 2s infinite;
  opacity: 0;
  transition: all .4s ease-out;
}

.card-item::after {
  content: '';
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(12px);
}

.card-item > .card-content {
  position: relative;
  z-index: 2;
  width: $card-size-l;
  height: $card-size-l;
  margin: 2px;
  background-color: var(--util-800);

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 720px) {
  .card-item > .card-content {
    width: $card-size-m;
    height: $card-size-m;
  }

  @media screen and (max-width: 500px) {
  }
  .card-item > .card-content {
    width: $card-size-s;
    height: $card-size-s;
  }
}

.card-item:hover {
  transform: scale(1.015);
}

.card-content:hover + .card-light {
  opacity: 1;
}

@keyframes card-item-before-rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}