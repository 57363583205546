.gallery-container {
  position: relative;
  padding-top: 17vh;
  margin-bottom: 17vh;
}

.gallery {
  position: relative;
}

.gallery-card {
  position: relative;
  width: 100%;
  transform: scale(1);
  transition: transform .12s ease-out;
}

.gallery-card:hover {
  transform: scale(0.98);
}