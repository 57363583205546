@import "colors";
@import "fonts/roboto-mono/robotoMono";
@import "fonts/racama/racama";
@import "fonts/sfUiText/sfUiText";
@import "fonts/eunoia/eunoia";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: var(--util-950);
    --foreground: var(--util-50);

    --muted: var(--util-50);
    --muted-foreground: var(--util-300);

    --popover: var(--secondary-700);
    --popover-foreground: var(--primary-500);

    --border: var(--util-900);
    --input: var(--util-900);

    --card: var(--primary-200);
    --card-foreground: var(--primary-500);

    --primary: var(--primary-400);
    --primary-foreground: var(--secondary-900);

    --secondary: var(--secondary-700);
    --secondary-foreground: var(--accent-400);

    --accent: var(--accent-400);
    --accent-foreground: var(--secondary-800);

    --danger-foreground: var(--util-50);
    --success-foreground: var(--util-50);
    --warning-foreground: var(--util-50);
    --information-foreground: var(--util-50);

    --ring: var(--primary-300);

    --radius: .5rem;
  }

  .dark {
    --background: var(--util-900);
    --foreground: var(--util-50);

    --muted: var(--util-700);
    --muted-foreground: var(--util-100);

    --accent: var(--accent-500);
    --accent-foreground: var(--util-50);

    --popover: var(--util-900);
    --popover-foreground: var(--primary-50);

    --border: var(--util-900);
    --input: var(--primary-600);

    --card: var(--util-900);
    --card-foreground: var(--primary-50);

    --primary: var(--primary-700);
    --primary-foreground: var(--primary-50);

    --secondary: var(--secondary-800);
    --secondary-foreground: var(--secondary-50);

    --destructive: var(--secondary-900);
    --destructive-foreground: var(--secondary-50);

    --ring: var(--primary-400);

    --radius: .5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

*, *::before, *::after {
  box-sizing: border-box;
}
html, body {
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  touch-action: pan-y;
  user-select: text;
}
::-webkit-scrollbar {
  display: none;
}
* {
  scrollbar-width: none;  /* Mozilla */
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  cursor:pointer;
}
a:visited {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a.no-deco:hover {
  text-decoration: none;
}
.link {
  cursor:pointer;
}
::-moz-selection { /* Code for Firefox */
  color:#fff;
  background: var(--primary-500);
  text-shadow: 0 0 10px #fff;
  text-shadow: 0 0 calc(0.72rem + 0.22vw) #fff;
}
::selection {
  color:#fff;
  background: var(--primary-500);
  text-shadow: 0 0 calc(0.72rem + 0.22vw) #fff;
}
.app, button {
  font-family: "SF UI Text", sans-serif;
  font-size: clamp(12px, 1.8vw, 18.5px);
  color: var(--foreground);
  letter-spacing: calc(.015rem + .002vw + .002vh);
}
h1, h2, h3, h4, h5 {
  margin: 0;
  text-decoration: none;
  font-weight: inherit;
}
h1, h2, h3 {
  font-family: "SF UI Text", sans-serif;
  font-size: clamp(15px, 1.9vw, 21px);
  letter-spacing: calc(.01rem + .001vw + .001vh);
}
h5 {
  font-size: clamp(12px, 1.8vw, 18.5px);
}
.opacity {
  &--secondary{
    opacity: .6;
  }
  &--accent{
    opacity: .3;
  }
}
.text {
  &--grand{
    font-size: clamp(84px, 15vw, 244px);
  }
  &--xl{
    font-size: clamp(42px, 9vw, 122px);
  }
  &--l{
    font-size: clamp(21px, 2.65vw, 32px);
  }
  &--ml{
    font-size: clamp(15px, 1.9vw, 19.5px);
  }
  &--s{
    font-size: clamp(11px, 1.8vw, 16px);
    letter-spacing: calc(0.015rem + 0.0025vw + 0.0025vh);
  }
  &--xs{
    font-size: clamp(10px, 1.65vw, 14px);
    letter-spacing: calc(0.015rem + 0.0025vw + 0.0025vh);
  }
  &--secondary{
    opacity: .7;
  }
  &--center{
    text-align: center;
  }
  &--left{
    text-align: left;
  }
  &--right{
    text-align: right;
  }
}
.wrap {
  max-width: 100px;
  word-wrap: break-word;
}
.icon {
  width: calc(.7rem + .2vw + .2vh);
  &--l {
    width: calc(2rem + 1.45vw + 1.45vh);
  }
  &--m {
    width: calc(1rem + .45vw + .45vh);
  }
  &--s {
    width: calc(.5rem + .15vw + .15vh);
  }
}
.stick-to-top{
  position:absolute;
  top:0;
}
.stick-to-bottom{
  position:absolute;
  bottom:0;
}
.full-width {
  width: 100% !important;
}
.full-height {
  height: 100% !important;
}
.center {
  position:relative;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}
.center-vertical {
  position:relative;
  top:50%;
  transform: translateY(-50%);
}
.center-horizontal {
  position:relative;
  left: 50%;
  transform: translateX(-50%);
}
.position {
  &--right{
    position:relative !important;
    left: 100%;
    transform: translateX(-100%);
  }
  &--left{
    position:relative !important;
    right: 100%;
    transform: translateX(100%);
  }
}
.center-container {
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.content-padding {
  padding:calc(.25rem + .5vw + .5vh) !important;
}
.no-padding-margin {
  padding: 0 !important;
  margin: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.spacer{
  margin-top:calc(.75rem + .5vw + .5vh) !important;
  margin-bottom:calc(.75rem + .5vw + .5vh) !important;
  &--s{
    margin-top:calc(.25rem + .2vw + .2vh) !important;
    margin-bottom:calc(.25rem + .2vw + .2vh) !important;
  }
  &--l{
    margin-top:calc(1.25rem + 1vw + 1vh) !important;
    margin-bottom:calc(1.25rem + 1vw + 1vh) !important;
  }
}
.divider {
  position:relative;
  width:100%;
  border:.5px solid var(--v-theme-primary);
  margin-top:calc(.35rem + .2vw + .2vh) !important;
  margin-bottom:calc(.35rem + .2vw + .2vh) !important;
}
.spacer.spacer-divider {
  opacity: .4;
  margin-top:calc(.75rem + .5vw + .5vh) !important;
  margin-bottom:calc(.75rem + .5vw + .5vh) !important;
}
.spacer.spacer-divider {
  border:.5px solid var(--v-theme-primary);
}
.top-spacer {
  margin-top:calc(.75rem + .5vw + .5vh) !important;
  &--s{
    margin-top:calc(.25rem + .2vw + .2vh) !important;
  }
}
.bottom-spacer {
  margin-bottom:calc(.75rem + .5vw + .5vh) !important;
  &--s{
    margin-bottom:calc(.25rem + .2vw + .2vh) !important;
  }
}
.left-spacer {
  margin-left:calc(.75rem + .5vw + .5vh) !important;
  &--s{
    margin-left:calc(.25rem + .2vw + .2vh) !important;
  }
}
.right-spacer {
  margin-right:calc(.75rem + .5vw + .5vh) !important;
  &--s{
    margin-right:calc(.25rem + .2vw + .2vh) !important;
  }
}
@media screen and (max-width: 1200px) {
  .cols{
    &--dynamicto12{
      min-width: 350px !important;
    }
    &--dynamic2{
      min-width: 150px !important;
    }
  }
}
@media screen and (min-width: 1200px) {
  .cols{
    &--dynamicto12{
      min-width: 350px !important;
    }
    &--dynamic2{
      max-width: 350px !important;
      min-width: 150px !important;
    }
  }
}
.hide {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
.app > .browser-router{
  position: relative;
  z-index: 1;
}
.app > .fixed-background {
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  opacity: 0;
  mix-blend-mode: lighten;
}

@keyframes rainbow-text-animation {
  0%, 100% {
    text-shadow:
            0 0 0 var(--secondary-500),
            0 0 0 var(--secondary-500),
            0 0 0 var(--secondary-500),
            0 0 0 var(--secondary-500);
  }
  25% {
    text-shadow:
            0 0 0 var(--primary-500),
            0 0 0 var(--primary-500),
            0 0 0 var(--accent-500),
            0 0 0 var(--accent-500);
  }
  50% {
    text-shadow:
            0 0 0 var(--accent-500),
            0 0 0 var(--accent-500),
            0 0 0 var(--primary-500),
            0 0 0 var(--primary-500);
  }
  75% {
    text-shadow:
            0 0 0 var(--secondary-500),
            0 0 0 var(--secondary-500),
            0 0 0 var(--accent-500),
            0 0 0 var(--accent-500);
  }
}

.rainbow-text {
  color: transparent;
  animation: rainbow-text-animation 3s linear infinite;
}