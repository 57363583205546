@font-face {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Text Regular'), url('SFUIText-Regular.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: italic;
  font-weight: normal;
  src: local('SF UI Text Italic'), url('SFUIText-RegularItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 100;
  src: local('SF UI Text Light'), url('SFUIText-Light.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: italic;
  font-weight: 100;
  src: local('SF UI Text Light Italic'), url('SFUIText-LightItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 300;
  src: local('SF UI Text Medium'), url('SFUIText-Medium.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: italic;
  font-weight: 300;
  src: local('SF UI Text Medium Italic'), url('SFUIText-MediumItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 500;
  src: local('SF UI Text Semibold'), url('SFUIText-Semibold.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: italic;
  font-weight: 500;
  src: local('SF UI Text Semibold Italic'), url('SFUIText-SemiboldItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 700;
  src: local('SF UI Text Bold'), url('SFUIText-Bold.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: italic;
  font-weight: 700;
  src: local('SF UI Text Bold Italic'), url('SFUIText-BoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 900;
  src: local('SF UI Text Heavy'), url('SFUIText-Heavy.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Text';
  font-style: italic;
  font-weight: 900;
  src: local('SF UI Text Heavy Italic'), url('SFUIText-HeavyItalic.woff') format('woff');
}