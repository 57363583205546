$offset-top-l:       100px;
$offset-top-m:       50px;
$offset-top-s:       20px;
$padding-l:          25vw;
$padding-m:          100px;
$padding-s:          12px;
$transition-nav:     all .06s ease-in;
$transition-anchor:  all .28s ease-out;
$transition-app:     all .44s cubic-bezier(.03,.49,.33,1);
$transition-text:    all .06s ease-out;
$anchor-open-height: 280px;
$navigation-bg:      #0c0b0bAF;

.browser-router {
  transform: scale(1);
  transition: $transition-app;
}

.navigation-container {
  position: fixed;
  z-index: 2;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-right: 0;
}

.navigation-container::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
  transition: $transition-app;
}

.navigation {
  position: absolute;
  padding-left: $padding-l;
  padding-right: $padding-l;
  top: 0;
  width: 100%;
  background-color: $navigation-bg;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  pointer-events: all;
  transition: $transition-nav;
}

.navigation.closed {
  transform: translateY(-100%);
}

.navigation-bar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation-bar > .logo {
  position: relative;
}

.navigation-bar > .anchors {
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
  white-space: nowrap;
  flex-grow: 1;
}

.anchor-container {
  position: relative;
}

.navigation-bar > .anchors > .anchor-item > .anchor-container > .animation-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: $transition-anchor;
}

.navigation-bar > .anchors > .anchor-item > a{
  transition: $transition-text;
}

.navigation-bar > .anchors > .anchor-item > a.disabled {
  color: var(--util-500);
}

.navigation-bar > .anchors > .anchor-item > .anchor-container > .animation-container.open {
  height: $anchor-open-height;
  opacity: 1;
  transition: $transition-anchor;
}

.navigation-bar > .anchors > .anchor-item > .anchor-container > .animation-container > .anchor-open {
  position: absolute;
  top: 0;
  left: 0;
  height: $anchor-open-height;
}

.navigation-externals {
  position: fixed;
  z-index: 3;
  height: 50vh;
  right: 0;
  bottom: 0;
  pointer-events: all;
  mix-blend-mode: difference;
}

.navigation-container.open::before {
  pointer-events: all;
  -webkit-backdrop-filter: blur(64px);
  backdrop-filter: blur(64px);
}

.navigation-container.open .navigation-anchors{
  right: 10vw;
}

@media screen and (max-width: 1020px) {
  .navigation  {
    padding-left: $padding-m;
    padding-right: $padding-m;
  }
}

@media screen and (max-width: 500px) {
  .navigation  {
    padding-left: $padding-s;
    padding-right: $padding-s;
  }
}