@import "../../../views/home/home.scss";

.line-art-container {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  overflow: hidden;
}

.line-art-container > .line-art-holder {
  position: absolute;
  height: 100%;
  width: clamp(550px, 100vw, 1920px);
  bottom: 0;
  left: calc($padding-l - 50px - 1px);
}

.line-art-holder::before {
  content: '';
  position: absolute;
  height: calc(200vh + 1px);
  width: 100%;
  top: -200vh;
  border-left: 1px solid var(--foreground);
}

.line-art-holder::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border-left: 1px solid var(--foreground);
}

.line-art-holder.introduction::before { visibility: hidden; }
.line-art-holder.wool::after { visibility: hidden; }

.line-art-container > .line-art-holder.introduction {
  bottom: calc(100px - 38% + 14vw);
}

.line-art-container > .line-art-holder.dollar {
  width: clamp(550px, 100vw, 1210px);
  bottom: calc(-80% + 7vw);
}

.line-art-container > .line-art-holder.heart {
  width: clamp(550px, 100vw, 1210px);
  bottom: calc(-10% + 7vw);
}

.line-art-container > .line-art-holder.wool {
  width: calc(100% - $padding-l);
  bottom: calc(-60% + 20vw);
}

@media screen and (max-width: 1020px) {
  .line-art-container > .line-art-holder {
    left: calc($padding-m - 15px - 1px);
  }

  .line-art-container > .line-art-holder.wool {
    width: calc(100% - $padding-m);
    bottom: calc(-75% + 20vw);
  }
}

@media screen and (max-width: 500px) {
  .line-art-container > .line-art-holder {
    left: calc($padding-s - 7px - 1px);
  }

  .line-art-container > .line-art-holder.wool {
    width: calc(100% - $padding-s);
    bottom: calc(-67% + 20vw);
  }
}