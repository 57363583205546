// Primary Colors
$primary-50: #ffb5b5;
$primary-100: #ffa3a3;
$primary-200: #ff9090;
$primary-300: #ff6b6b;
$primary-400: #ff5959;
$primary-500: #ff4646;
$primary-600: #e63f3f;
$primary-700: #b33131;
$primary-800: #992a2a;
$primary-900: #661c1c;
$primary-950: #330e0e;

// Secondary Colors
$secondary-50: #d0b6ff;
$secondary-100: #c4a4ff;
$secondary-200: #b892ff;
$secondary-300: #a16dff;
$secondary-400: #955bff;
$secondary-500: #8949ff;
$secondary-600: #6622e3;
$secondary-700: #5813d9;
$secondary-800: #4f11c3;
$secondary-900: #460fae;
$secondary-950: #350b82;

// Accent Colors
$accent-50: #f89582;
$accent-100: #f89582;
$accent-200: #f7846d;
$accent-300: #f57259;
$accent-400: #f46144;
$accent-500: #f34f2f;
$accent-600: #e84020;
$accent-700: #d13a1d;
$accent-800: #ba331a;
$accent-900: #a22d16;
$accent-950: #742010;

// Util Colors
$util-50: #f3f1ed;
$util-100: #f2f0eb;
$util-200: #F0EEE9;
$util-300: #c0beba;
$util-400: #a8a7a3;
$util-500: #908f8c;
$util-600: #545251;
$util-700: #262423;
$util-800: #181817;
$util-900: #171716;
$util-950: #100f0f;
$util-1000: #0c0b0b;

// Emotion Colors
$success: #b5fc95;
$danger: $primary-200;
$warning: #ffe8a4;
$information: #abccfc;

:root {
  --primary-50: #{$primary-50};
  --primary-100: #{$primary-100};
  --primary-200: #{$primary-200};
  --primary-300: #{$primary-300};
  --primary-400: #{$primary-400};
  --primary-500: #{$primary-500};
  --primary-600: #{$primary-600};
  --primary-700: #{$primary-700};
  --primary-800: #{$primary-800};
  --primary-900: #{$primary-900};
  --primary-950: #{$primary-950};

  --secondary-50: #{$secondary-50};
  --secondary-100: #{$secondary-100};
  --secondary-200: #{$secondary-200};
  --secondary-300: #{$secondary-300};
  --secondary-400: #{$secondary-400};
  --secondary-500: #{$secondary-500};
  --secondary-600: #{$secondary-600};
  --secondary-700: #{$secondary-700};
  --secondary-800: #{$secondary-800};
  --secondary-900: #{$secondary-900};
  --secondary-950: #{$secondary-950};

  --accent-50: #{$accent-50};
  --accent-100: #{$accent-100};
  --accent-200: #{$accent-200};
  --accent-300: #{$accent-300};
  --accent-400: #{$accent-400};
  --accent-500: #{$accent-500};
  --accent-600: #{$accent-600};
  --accent-700: #{$accent-700};
  --accent-800: #{$accent-800};
  --accent-900: #{$accent-900};
  --accent-950: #{$accent-950};

  --util-50: #{$util-50};
  --util-100: #{$util-100};
  --util-200: #{$util-200};
  --util-300: #{$util-300};
  --util-400: #{$util-400};
  --util-500: #{$util-500};
  --util-600: #{$util-600};
  --util-700: #{$util-700};
  --util-800: #{$util-800};
  --util-900: #{$util-900};
  --util-950: #{$util-950};
  --util-1000: #{$util-1000};

  --success: #{$success};
  --danger: #{$danger};
  --warning: #{$warning};
  --information: #{$information};
}