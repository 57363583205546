@font-face {
  font-family: "Roboto Mono";
  src: url('./RobotoMono-Thin.ttf') format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Mono";
  src: url('./RobotoMono-Light.ttf') format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Mono";
  src: url('./RobotoMono-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Mono";
  src: url('./RobotoMono-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Mono";
  src: url('./RobotoMono-Bold.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
}