.page-root {
  position: absolute;
  width: 100%;
  min-width: 100%;
  overflow:hidden;
}
.page-root .neutral-font{
  font-family: "Product Sans", "Segoe UI", sans-serif;
  font-size: calc(.42rem + .4vw);
}
.page-navigation {
  position:fixed;
  top:0;
  width:100%;
  z-index:99;
  padding-top: calc(.8vmin + 1.35rem);
  padding-bottom: calc(.8vmin + 1.35rem);
  padding-right: calc(.9vmin + 1.45rem);
}
.page-navigation-content {
  position:relative;
}
.page-navigation-content > .most-right {
  position:absolute;
  right:0;
}
.page-navigation > .most-left {
  position:absolute;
  left:0;
}
.page-section {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(35vmin + 30rem);
  -webkit-user-select: text; /* Safari */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* IE10+/Edge */
  user-select: text; /* Standard */
}
.page-section > .page-section-item {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: calc(1.5vmin + .75rem);
  padding-bottom: calc(1.5vmin + .75rem);
}
.page-section-adjustable {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: calc(6vmin + 1rem);
  padding-bottom: calc(6vmin + 1rem);
  -webkit-user-select: text; /* Safari */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* IE10+/Edge */
  user-select: text; /* Standard */
}
.page-section-adjustable > .page-section-item {
  position: relative;
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: calc(1.5vmin + .75rem);
  padding-bottom: calc(3.5vmin + .75rem);
}
@media screen and (max-width: 1020px) {
  .page-section {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  .page-section > .page-section-item {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding-left: 4.5%;
    padding-right: 4.5%;
    padding-top: calc(1.5vmin + .75rem);
    padding-bottom: calc(2.5vmin + .75rem);
  }
  .page-section-item.bigger-padding{
    padding-left: calc(1.5rem + 4.5vw) !important;
    padding-right: calc(1.5rem + 4.5vw) !important;
  }
  .page-section-adjustable > .page-section-item {
    position: relative;
    padding-left: 4.5%;
    padding-right: 4.5%;
    padding-top: calc(1.5vmin + .75rem);
    padding-bottom: calc(2.5vmin + .75rem);
  }
}
.page-grid-item{
  padding:calc(2.5vmin + .75rem);
}
.page-section-footer{
  position:absolute;
  bottom:-10px;
  left:0;
}
.page-section-halfcol-image{
  width:calc(3rem + 10vw);
}
.page-section-fullcol-image{
  width:calc(6rem + 32vw + 15vh);
}
.page-section-enumeration-image{
  width:calc(2rem + 10vmin);
  margin:1rem;
}
.page-section-background{
  z-index:0;
  position:absolute;
  width:100%;
  height:100%;
}
.page-section-background-item{
  position:absolute;
  transition: 0.1s ease-out all;
}

.dark-page-section{
  background-color: var(--dark-backgroundcolor);
}
.page-section-min-padding{
  padding-left:2vw;
  padding-right:2vw;
}
.page-section-backgroundimg {
  background-size: cover;
  min-height:calc(10vw + 40vh);
}
.page-section-bottom-padding{
  padding-bottom:calc(7.5rem + 20vh);
}
.section{
  &--10{
    width:10%;
  }
  &--25{
    width:25%;
  }
  &--50{
    width:50%;
  }
  &--dynamic10{
    width:10%;
  }
  &--dynamic25{
    width:25%;
  }
  &--dynamic50{
    width:50%;
  }
  &--full{
    width:100%;
  }
}
@media screen and (max-width: 1200px) {
  .section{
    &--dynamic10{
      width:20%;
    }
    &--dynamic25{
      width:50%;
    }
    &--dynamic50{
      width:100%;
    }
  }
}
@media screen and (min-width: 1200px) {
  .section{
    &--dynamic10{
      width:10%;
    }
    &--dynamic25{
      width:25%;
    }
    &--dynamic50{
      width:50%;
    }
  }
}