$height-l: 200px;
$height-m: 150px;

.my-works-container {
  position: relative;
  padding-top: calc(17vh + 100px);
}

.my-works {
  position: relative;
}

.my-works::before {
  content: '';
  position: relative;
  display: block;
  width:100%;
  border-bottom: solid 1px var(--util-50);
}

.my-works .item, .my-works .item > .animated-background > .item {
  display: inline-flex;
  align-items: center;
  justify-content: start;
  width:100%;
  height: $height-l;
}

.my-works .item {
  position: relative;
  z-index: 0;
  border-bottom: solid 1px var(--util-50);
}

.my-works .item > .animated-background > .item {
  position: absolute;
  width: 100vw;
  color: var(--util-950);
  background: linear-gradient(130deg, var(--primary-400) 0%, var(--accent-700) 40%, var(--secondary-700) 100%);
}

.my-works .item > .animated-background {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  left: 0;
  height: 100%;
}

@media screen and (max-width: 1020px) {
  .my-works .item, .my-works .item > .animated-background > .item {
    height: $height-m;
  }
}