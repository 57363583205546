.dragon-scene-container {
  position: relative;
  width: clamp(400px, 100vw, 100vw);
  height: clamp(700px, 130vh, 130vh);
}

.dragon-scene-container > canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}