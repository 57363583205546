.story {
  position: relative;
}

.story-line {
  position: relative;
}

.story-line .content-box {
  position: relative;
}

.story-line > .full-width-container {
  position: absolute;
  height: calc(100% + 50vh);
  width: 100vw;
  top: 0;
  left: 50%;
  transform: translateX(-50vw);
  overflow: hidden;
}

.story-line.engineering > .full-width-container::before {
  content: '';
  position: absolute;
  height: calc(100% - 50vh);
  width: 100%;
  background-color: var(--util-1000);
}

.phone-scene-holder {
  position: absolute;
  z-index: 1;
  top: 100px;
  right: 25vw;
  width: 350px;
  transform: rotate(20deg);
}

.story-line > .full-width-background {
  position: absolute;
  height: 100%;
  width: 100vw;
}

.story-line.start .background-container {
  position: absolute;
  width: 100vw;
  height: calc(100% + 17vh);
  z-index: 0;
  top: -17vh;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(0deg, var(--util-1000) 50%, rgba(0,0,0,0) 100%);
  overflow: hidden;
}

.story-line.start .background-container::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 40%;
  bottom: 0;
  background: linear-gradient(0deg, var(--util-1000) 40%, rgba(0,0,0,0) 100%);
}

.story-line.start .background {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1440px) {
  .phone-scene-holder {
    right: 15vw;
  }
}

@media screen and (max-width: 1020px) {
  .phone-scene-holder {
    right: 10vw;
  }
}

@media screen and (max-width: 500px) {
  .phone-scene-holder {
    right: 0;
  }
}